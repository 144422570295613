import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import * as jQuery from 'jquery';
import { BehaviorSubject } from 'rxjs';
import { ChildProject, IChildProject, MasterProject, State } from '../models/project.model';
import { Request } from '../models/easydiadem/request.model';
import { LivecoAPIService } from './liveco-api.service';
import { FlowType } from '../models/proofreading.model';
import { Id } from '../models/base.model';
import { Query, ResolverParams, ResolverService } from './api/resolver.service';
import { KFeathers } from './api/feathers.service';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from './loader.service';
import { AuthService } from './auth.service';
import { ConfirmDialogComponent } from '../shared/components/dialog/confirm-dialog/confirm-dialog.component';
import { RequestsService } from './requests.service';


// WRITE HERE THE EMAIL OF USERS WHO CAN ARCHIVE PROJECTS
// This is pure shit Magalie. Just do a permission on the user ><
const archive_users = [
    "cpp-diadem@superdev.fr",
    "j.grandjean@diadem-pack.com",
    "t.grandjean@diadem-pack.com",
    "b.kichenin@diadem-pack.com",
    "p.nuzillat@diadem-pack.com",
    "f.gautret@diadem-pack.com"
];


@Injectable({
    providedIn: 'root',
})
export class ProjectService extends ResolverService<ChildProject> {
    path: string = 'project';
    model: new (any: any) => ChildProject = ChildProject;

    onNewStatusChange: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

    // TO DO Fix childJob and childData inconsistency (same for masterJob)
    protected _childJob: BehaviorSubject<ChildProject | null> = new BehaviorSubject<any>(null);
    protected _masterJob: BehaviorSubject<MasterProject | null> = new BehaviorSubject<any>(null);


    get childSubject() {
        return this._childJob;
    }
    get masterSubject() {
        return this._masterJob;
    }

    get masterData() { return this._masterJob.value; };
    set masterData(m: any) {
        this._masterJob.next(m);
        this.onNewStatusChange.next(_.isNil(this._masterJob.value?._id));
    }

    get childData() { return this._childJob?.value; };
    set childData(m: any) {
        this._childJob.next(m);
        this.onNewStatusChange.next(_.isNil(this._childJob.value?._id));
    }

    get masterId() { return this.masterData?._id || ""; }
    set masterId(s: string) {
        if (this._masterJob.value)
            this._masterJob.value._id = s;

        this._masterJob.next(this._masterJob.value);
        this.onNewStatusChange.next(_.isNil(this.masterData?._id));
    }

    get childId() { return this.childData?._id; }
    set childId(s: string) {
        if (this._childJob.value)
            this._childJob.value._id = s;

        this._childJob.next(this._childJob.value);
        this.onNewStatusChange.next(_.isNil(this.childData?._id));
    }

    get ed_request$(): BehaviorSubject<Request | undefined> {
        return this.s_requests.current$;
    }
    get ed_request(): Request | undefined {
        return this.s_requests.current;
    }
    set ed_request(r: Request | undefined) {
        this.s_requests.current = r;
    }

    isNewProject(type: string = 'child'): boolean {
        return (type === 'child') ? (_.isNil(this.childId) || _.isEmpty(this.childId)) : (_.isNil(this.masterId) || _.isEmpty(this.masterId));
    }

    reset() {
        this.masterData = {};
        this.childData = {};
        this.resetCurrent();
        this.reloadEntities();
    }

    refresh() {
        this.masterSubject.next(this.masterData);
        this.childSubject.next(this.childData);
    }

    makeSourcePantone(options: any[]) {
        let source = [];
        source = options.flatMap((currentValue, index) => {
            return currentValue.colors.flatMap((item: string) => {
                return { slug: currentValue.title, value: item };
            });
        });
        return source;
    }

    constructor(
        protected feathers: KFeathers,
        public s_requests: RequestsService,
        protected dialog: MatDialog,
        protected loader: LoaderService,
        protected auth: AuthService,
        protected liveco: LivecoAPIService) {
        super(feathers);
    }

    async getAvailableFlowType(projectID: Id) {
        const project: IChildProject = await this.get(projectID) as any;
        if (!project)
            return [];

        const flow = [];
        if (project.custom.general.service_exe) {
            flow.push(FlowType.EXE);
        }
        if (project.custom.general.service_repro) {
            flow.push(FlowType.REPRO);
        }
        if (project.custom.easydiadem?.flow) {
            return project.custom.easydiadem?.flow;
        }
        return flow;
    }

    openFileInPackZ(url: string) {
        jQuery.ajax({
            type: "POST",
            url: "http://127.0.0.1:9091/frameCGI/frame.cgi",
            contentType: "text/plain; charset=UTF-8",
            data: JSON.stringify({ application_name: "PACKZ", method: "frame.os.open_file_in_application", file_url: url })
        });
    }

    openFolderChild(project: IChildProject) {
        if (project && project.folders && project.folders.length) {
            let folderPath = project.folders[0] && project.folders[0].url ? project.folders[0].url : "";

            if (project?.folders[0]?.url) {
                if (project?.state === State.ARCHIVED) {
                    const baseUrl = project.folders[0].url;

                    const os = this.getOperatingSystem();

                    if (os === 'Windows') {
                        folderPath = baseUrl.replace(environment.cloudflow_url_base, environment.archives.windows);
                    }

                    if (os === "Mac") {
                        folderPath = baseUrl.replace(environment.cloudflow_url_base, environment.archives.mac);
                    }

                } else {
                    folderPath = project?.folders[0].url;
                }
            } else {
                folderPath = "";
            }

            this.openFolder(folderPath);
        }
    }

    openFolderParent(project: IChildProject) {
        if (project && project.folders && project.folders.length) {
            let folderPath = project.folders[0] && project.folders[0].url ? project.folders[0].url : "";

            if (project?.folders[0]?.url) {
                if (project?.state === State.ARCHIVED) {
                    const baseUrl = project.folders[0].url;

                    const os = this.getOperatingSystem();

                    if (os === 'Windows') {
                        folderPath = baseUrl.replace(environment.cloudflow_url_base, environment.archives.windows);
                    }

                    if (os === "Mac") {
                        folderPath = baseUrl.replace(environment.cloudflow_url_base, environment.archives.mac);
                    }

                } else {
                    folderPath = project?.folders[0].url;
                }
            } else {
                folderPath = "";
            }

            if (folderPath && folderPath.split('/').length > 1) {
                folderPath = folderPath.split('/').slice(0, -1).join('/');
            }

            this.openFolder(folderPath);
        }
    }

    openFolder(url: string) {
        jQuery.ajax({
            type: "POST",
            url: "http://127.0.0.1:9091/frameCGI/frame.cgi",
            contentType: "text/plain; charset=UTF-8",
            data: JSON.stringify({ method: "frame.os.open_folder_in_os", folder_url: url })
        });
    }

    getOperatingSystem() {
        let operatingSystem = 'Inconnu';
        if (navigator.userAgent.indexOf('Win') !== -1) { operatingSystem = 'Windows'; }
        if (navigator.userAgent.indexOf('Mac') !== -1) { operatingSystem = 'Mac'; }
        if (navigator.userAgent.indexOf('X11') !== -1) { operatingSystem = 'UNIX'; }
        if (navigator.userAgent.indexOf('Linux') !== -1) { operatingSystem = 'Linux'; }
        return operatingSystem;
    }

    openFolderOld(childJob: any) {
        if (childJob && childJob.folders && childJob.folders.length) {
            let folderPath = childJob.custom.general.absolute_url;

            //folderPath = "/Volumes/Archives 2/Pack/ST ROLAN/P2302183 - T_JOB 25359_Logo St Rolan/2. Exe/Relecture001/_DOCS_DEFINITIFS/"

            const os = this.getOperatingSystem();

            if (folderPath && (os === 'Windows' || os === 'Mac')) {

                let finalPath = "";

                const splittedPath = folderPath.split('/');

                for (let i = 0; i < splittedPath.length - 1; i++) {
                    finalPath += splittedPath[i] + "/";
                }

                if (os === "Mac") {
                    finalPath = finalPath.replace(environment.regex_xraid_server, environment.volumes_mac).replace(environment.regex_srv_data_01, environment.volumes_mac);
                }

                // if (os === "Windows") {
                //     finalPath = finalPath.replace(/\//g, '\\');
                // }

                if (finalPath.indexOf('XRAID-SERVEUR/8 Archives') !== -1) {
                    finalPath = finalPath.replace('XRAID-SERVEUR/8 Archives', 'SRV-DATA-01/Archives 1');
                }

                if (finalPath.indexOf('XRAID-SERVEUR\\8 Archives') !== -1) {
                    finalPath = finalPath.replace('XRAID-SERVEUR\\8 Archives', 'SRV-DATA-01\\Archives 1');
                }

                this.openFolder(finalPath);
            }
        }
    }

    // ARCHIVE && DISARCHIVAGE
    async archive(identifier: string) {
        if (!this.canArchive() || !identifier) {
            return undefined;
        }

        let dialogRef = {
            panelClass: "dialog",
            data: {
                title: "ARE_YOU_SURE",
                text: "ARE_YOU_SURE_ARCHIVE",
                valid: "OK",
                noConfirm: false
            },
            width: "450px"
        };

        const response = await this.dialog.open(ConfirmDialogComponent, dialogRef).afterClosed().toPromise();
        if (response) {
            if (this.auth && this.auth.user && this.auth.user.email && archive_users.includes(this.auth.user.email) && identifier) {
                // LOADING
                this.loader.loading = true;
                console.log("TOTO");
                const res = await this.liveco.find({ identifier }, "/project-archive");
                console.log("FINI");
                this.loader.loading = false;
                if (res.result) {
                    this.childData = res.result;
                }
                return res.result;
            }
        }

        return undefined;
    }


    async unarchive(identifier: string) {
        if (!this.canArchive() || !identifier) {
            return undefined;
        }

        let dialogRef = {
            panelClass: "dialog",
            data: {
                title: "ARE_YOU_SURE",
                text: "ARE_YOU_SURE_UNARCHIVE",
                valid: "OK",
                noConfirm: false
            },
            width: "450px"
        };

        const response = await this.dialog.open(ConfirmDialogComponent, dialogRef).afterClosed().toPromise();
        if (response) {
            if (this.auth && this.auth.user && this.auth.user.email && archive_users.includes(this.auth.user.email) && identifier) {
                // LOADING
                this.loader.loading = true;
                const res = await this.liveco.find({ identifier }, "/project-unarchive");
                this.loader.loading = false;
                if (res.result) {
                    this.childData = res.result;
                }
                return res.result;
            }
        }

        return undefined;
    }



    canArchive(): boolean {
        return !!(this.auth && this.auth.user && this.auth.user.email && archive_users.includes(this.auth.user.email));
    }

    isArchived(): boolean {
        return this.childData?.state === State.ARCHIVED;
    }

    override find(queries?: Query, params?: ResolverParams): Promise<ChildProject[]> {
        console.log("FIND PROJECTS", queries);
        return super.find(queries, params);
    }
}
