<form id="login-form" [formGroup]="form">
    <div class="logo"><img src="assets/svg/LogoDiadem.svg" alt="logo-diadem"></div>
    <mat-form-field appearance="outline" class="w-100">
        <mat-label>{{'USERNAME'|translate}}</mat-label>
        <input matInput formControlName="username" [ngClass]="{ 'is-invalid': controls.username.errors }" />
        <mat-error *ngIf="controls.username.errors"> {{'INVALID_USERNAME'|translate}} </mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w-100 mb-1">
        <mat-label>{{'PASSWORD'|translate}}</mat-label>
        <input matInput type="password" formControlName="password" [ngClass]="{ 'is-invalid': controls.password.errors }" />
        <mat-error *ngIf="controls.password.errors"> {{'INVALID_PASSWORD'|translate}} </mat-error>
    </mat-form-field>


    <a class="text-tw-primary w-fit cursor-pointer hover:text-tw-dark-primary" (click)="onForgotPassword()">
        <div class="flex gap-2 items-center">
            <mat-icon>lock_reset</mat-icon>
            <span>{{'FORGOT_PASSWORD' | translate}}</span>
        </div>
    </a>
    
    
    <div class="btn-container text-right">
        <button mat-flat-button [disabled]="isDisabled() || isLoading" color="accent" type="button" (click)="onSubmit()"> {{'CONNECTION'|translate}} </button>
    </div>
</form>